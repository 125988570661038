#work-container {
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        li {
            margin: 24px;
            position: relative;

            border-radius: 8px;
            width: 320px;
            background-color: $primary;

            display: flex;
            flex-direction: column;

            box-shadow: $secondary 0px 0px 0px 2px inset, $white 10px -10px 0px -3px, $secondary 10px -10px;
            transition: transform 1s;

            &:hover {
                transform: scale(1.05);
                cursor: pointer;
            }

          
            .top-employment {
                padding: 24px;
                h2 {
                    color: $secondary;
                    font-weight: bold;
                }
                color: $black;
            }

            .bottom-employment {
                padding: 24px;

                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                color: $black;

                .title {
                    color: $secondary;
                }
            }
        }
    }
}