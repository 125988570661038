// General Use
$primary: #F8F0FB;
$secondary: #6320EE;
$secondary-light: #8075FF;

$grey: #cad5cac4;
$white: #f5f5f5;
$black: #211A1D;
$black-faded: #222633;

// Import all design files based on section
@import "./sections/intro/Intro.scss";
@import "./sections/work/Work.scss";
@import "./sections/projects/Projects.scss";
@import "./sections/research/Research.scss";
@import "./sections/skills/Skills.scss";
@import './sections/contact/Contact.scss';

// Global Adjustments
body {
    background-color: $secondary;
    margin: 0;
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
textarea,
input {
    margin: 0;
    padding: 0;
}

p,
textarea,
input {
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Prata', serif;
}

button {
    transition: 0.3s;
}

a {
    text-decoration: none;
    color: black;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.p5Canvas { // p5 Sketch animation
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.section-container {
    padding: 16px 128px;

    .section-subtitle {
        text-align: center;
        font-size: 32px;
        padding-bottom: 24px;
        color: $white;
    }
}
.time-length {
    position: absolute;
    top: -18px;
    right: -18px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $black;
    padding: 4px 12px;
    text-align: center;
    color: $black;
}

// Wave Designs
.top-wave {
    margin-bottom: -4px;
}

.bottom-wave {
    margin-top: -4px;
}

.top-wave {
    position: relative;

    .wave-one {
        z-index: 10;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
    }

    .wave-two {
        position: absolute;
        top: 6px;
        left: 0px;
        width: 100%;
        z-index: 20;
    }

    .wave-overlay {
        position: absolute;
        top: 10px;
        left: 0px;
        width: 100%;
        z-index: 30;
    }
}

.bottom-wave {
    position: relative;

    .wave-one {
        z-index: 30;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
    }

    .wave-two {
        position: absolute;
        top: 6px;
        left: 0px;
        width: 100%;
        z-index: 20;
    }

    .wave-overlay {
        position: absolute;
        top: 10px;
        left: 0px;
        width: 100%;
        z-index: 10;
    }
}

// Bottom Footer
#footer-wrapper {
    display: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $black-faded;
    border-top: 4px solid $black;
    color: white;
    font-size: 12px;

    padding-top: 16px;
    padding-bottom: 16px;

    #social-media-links {
        list-style: none;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 0;

        .social-media-wrapper {
            margin: 4px 6px;
        }

    }

    .social-media-icon {
        width: 24px;
        height: 24px;
        background: white;
        border-radius: 50%;
        padding: 6px;

        &:hover {
            background: $secondary-light;
            transition: 0.3s;
        }
    }

    #math-bottom-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
    }

    .math-time {
        font-size: 10px;
    }

    .math-formula {
        font-size: 10px;
        margin-left: 8px;
    }

    .math-formula>a {
        text-decoration: none;
        color: $secondary-light;
    }

    .math-formula>a:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    .copyright-tag {
        text-align: center;
        margin-top: 0;
        font-size: 10px;
    }
}



@media only screen and (max-width: 800px) {

    .section-container {
        padding: 128px 0px;
    }
}