#intro-container {
    padding-bottom: 128px;
    height: 800px;
    position: relative;

    #intro-text {
        position: absolute;
        top: calc(50%);
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-left: 4px solid #42f581;
        padding: 12px 120px;

        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        border: 1px solid rgba(255, 255, 255, 0.88);

        animation: opacityOn 4s normal forwards;

        h1 {
            font-size: 80px;
            color: white;
        }

        @keyframes opacityOn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        p {
            color: $primary;
            font-size: 32px;
            text-align: left;

            #software-title {
                color: #f54293;
            }

            #finance-title {
                color: #a142f5;

            }

        }

        .arrow-notch {
            padding-right: 8px;
            font-size: 12px;
        }

        .text-shuffle {
            font-size: 18px;
            color: #42f581;
        }
    }
}

@media only screen and (max-width: 800px) {
    #intro-container {
        #intro-text {
            text-align: center;
            top: calc(50% - 200px);
            padding: 16px;
            align-items: center;

            h1 {
                line-height: 80px;
                text-align: center;
            }

            p {
                font-size: 24px;
                text-align: center;
            }

            .text-shuffle {
                font-size: 16px;
            }
        }
    }
}