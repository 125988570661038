#contact-container {
    padding-bottom: 244px;
    display: flex;
    justify-content: center;

    .inner-contact-container {
        background-color: $primary;
        border-radius: 12px;
        padding: 24px;

        width: 100%;
        max-width: 800px;
        min-width: calc(400px - 48px);

        display: flex;
        flex-direction: row;

        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        position: relative;


        min-height: 460px;
    }

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -60px;
        left: -60px;

        .pic-border {
            width: 260px;
            height: 260px;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            background-color: $secondary-light;
        }

        img {
            width: 240px;
            height: 240px;
            border-radius: 50%;
            border: 4px solid $secondary;
            z-index: 1;
        }
    }

    #form-success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;

        h2 {
            color: $secondary;
            font-size: 32px;
        }

        p {
            padding-top: 16px;
            line-height: 20px;
        }
    }

    .form-container {
        width: 100%;
        padding: 48px;
        padding-left: 200px;



        .reachout-header {
            color: $secondary;
        }

        .submit-bttn {

            position: absolute;
            bottom: 32px;
            right: 64px;
            padding: 8px 48px;
            border: none;
            background-color: $secondary;
            color: $white;
            border-radius: 4px;
            font-size: 16px;

            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;

            &:hover {
                cursor: pointer;
                filter: brightness(90%);
            }

            &:disabled,
            &[disabled] {

                background-color: $secondary-light;

                &:hover {
                    cursor: default;
                    filter: brightness(100%);
                }
            }
        }
    }

    .error-message {
        color: red;
        font-size: 12px;
    }

    .inputbox {
        &::before {
            overflow: hidden;
            position: relative;
        }

        position: relative;
        width: 100%;

        margin: 24px 0px;

        .required {
            color: red;
            position: absolute;
            top: 0px;
            right: -8px;
        }

        input,
        textarea {
            width: 100%;
            box-sizing: border-box;
            font-size: 16px;
            border: 0;
            background: none;
            border-bottom: 1px solid $secondary-light;
            outline: none;
            border-radius: 0;
            height: 30px;
            padding: 4px 12px;

            z-index: 999999999;

            &:focus,
            &:not(:placeholder-shown) {
                &~label {
                    color: $secondary;
                    transform: translateY(-20px);
                    font-size: 0.825em;
                    cursor: default;
                }
            }

            &:focus {
                &~.underline-item {
                    width: 100%;
                }
            }
        }

        textarea {
            resize: none;
            height: 120px;
            margin-bottom: -4px;
        }

        label {
            position: absolute;
            top: -2px;
            left: 0;
            height: 50px;
            line-height: 30px;
            color: $secondary-light;
            cursor: text;
            transition: all 200ms ease-out;
            z-index: 10;
            text-transform: capitalize;
            pointer-events: none;
        }

        .underline-item {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 0;
            height: 2px;
            background: $secondary;
            transition: all 200ms ease-out;
        }
    }
}

// Handles Mobile Screens
@media only screen and (max-width: 800px) {
    #contact-container {
        padding-bottom: 244px;
        display: flex;
        justify-content: center;

        .inner-contact-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 0;
            position: relative;

            .img-container {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                top: -128px;
            }

            .form-container {
                width: calc(100% - 96px);
                padding: 48px;
                padding-bottom: 112px;
                padding-top: 140px;

                .reachout-header {
                    text-align: center;
                }

                .submit-bttn {
                    position: absolute;
                    bottom: 48px;
                    left: 48px;
                    height: 48px;
                    width: calc(100% - 96px);
                }
            }
        }
    }
}