#skills-container {
    background: $black-faded;
    color: $white;

    #all-skill-groups {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .skill-group {
            margin: 12px;

            width: 320px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .skill-group-header {
                color: $primary;
                text-transform: capitalize;
                padding-bottom: 8px;
                text-align: center;
                width: 300px;
            }

            .skill-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                grid-gap: 12px;

                li {
                    padding: 6px 12px;
                    color: $black;

                    width: 160px;

                    border-radius: 12px;
                    border-top-left-radius: 2px;
                    background-color: $white;
                    text-align: left;

                    border-left: 12px solid $secondary-light;


                }
            }
        }
    }
}

// Handles Mobile
@media only screen and (max-width: 1000px) {
    #skills-container {
        #all-skill-groups {
            flex-wrap: wrap;

            .skill-group {
                width: 100%;
                align-items: center;
                justify-content: center;

                .skill-list {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 200px);
                    justify-content: center;
                    grid-gap: 12px;
                }
            }

        }
    }
}