#projects-container {
    background: $black-faded;

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        li {

            width: 320px;
            min-height: 300px;

            margin: 24px;
            padding: 24px;
            padding-bottom: 80px;

            border-top-left-radius: 50%;
            border-bottom-right-radius: 12px;

            position: relative;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

            background-color: $primary;

            display: flex;
            flex-direction: column;
            align-items: center;

            border-left: 8px solid $secondary-light;
            border-top: 8px solid $secondary-light;

            .project-header-container {
                min-height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                .project-header {
                    text-align: right;
                    font-size: 20px;
                    font-weight: normal;
                    color: $secondary;
                    padding-left: 64px;
                    width: 100%;
                }
            }



            .project-desc {
                text-align: left;
                font-size: 14px;
                padding-top: 12px;
                padding-bottom: 12px;
                color: $black;
            }

            .private-proj,
            .public-proj {
                position: absolute;
                top: -34px;
                right: 0;

                padding: 6px 18px;
                border-radius: 4px;
                font-size: 14px;

                border: none;

                
            }

            .private-proj {
                background-color: $black-faded;
                color: $grey;

            }

            .public-proj {
                background-color: $secondary-light;
                color: $white;
                &:hover {
                    cursor: pointer;
                    filter: brightness(90%);
                }
            }

            .technologies-used {

                border-top: 1px solid grey;
                position: absolute;
                bottom: 24px;
                left: 24px;
                width: calc(100% - 48px);

                padding-top: 12px;

                p {
                    font-size: 12px;
                    color: $black;
                    text-align: left;
                }



                .tech-header {
                    font-style: italic;
                    padding-right: 4px;
                }

            }

        }

        // li:nth-child(1) {
        //     border-top: 2px solid #ffa754;
        //     border-left: 2px solid #ffa754;
        // }

        // li:nth-child(2) {
        //     border-top: 2px solid #1fe4f5;
        //     border-left: 2px solid #1fe4f5;
        // }

        // li:nth-child(3) {
        //     border-top: 2px solid #68fc94;
        //     border-left: 2px solid #68fc94;
        // }

        // li:nth-child(4) {
        //     border-top: 2px solid #d972f8;
        //     border-left: 2px solid #d972f8;
        // }

        // li:nth-child(5) {
        //     border-top: 2px solid #e71c1c;
        //     border-left: 2px solid #e71c1c;
        // }

        // li:nth-child(6) {
        //     border-top: 2px solid #6ac2f1;
        //     border-left: 2px solid #6ac2f1;
        // }
    }

}