#research-container {
    color: white;

    #inner-research-container {
        display: flex;
        flex-direction: row;

        height: 800px;
        overflow-y: scroll;

        #report-list {
            width: 40%;

            position: relative;
            padding: 12px;

            li {
                padding: 12px 10px;
                position: relative;

                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                color: $grey;

                font-size: 16px;

                transition: 0.3s;

                &:hover {
                    color: $white;
                    cursor: pointer;
                }

                .report-year {
                    padding-right: 8px;
                }
            }

            li+li {
                border-top: 1px solid grey;
            }

            .active-report {
                color: white;

            }
        }

        #viewing-report {
            background-color: $primary;
            border-radius: 4px;
            width: 60%;

            position: relative;

            #report-info {
                height: calc(100% - 48px);
                overflow-y: scroll;
                padding: 24px;

                .report-header {
                    color: $secondary;

                    .report-keywords {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        li {
                            padding: 4px 12px;
                            background-color: $secondary;
                            margin: 4px;
                            color: $white;
                            border-radius: 4px;
                            font-size: 12px;
                        }
                    }
                }

                .report-description {
                    padding-top: 24px;
                    color: $black;
                }

                .report-abstract {
                    font-style: italic;
                    padding-top: 12px;

                    color: $black;

                    .abstract-key {
                        font-style: normal;
                        color: $secondary;
                    }
                }

                #bottom-buffer {
                    height: 100px;
                }
            }

            .redirect-bottom {
                position: absolute;
                bottom: 24px;
                right: 24px;

                display: flex;
                flex-direction: column;
                align-items: flex-end;

                #viewing-additional-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 8px;

                    .read-additional-bttn {
                        border: none;
                        display: flex;
                        justify-content: center;
                        flex-direction: row;
                        align-items: center;
                        padding: 6px 16px;
                        margin-left: 6px;
                        border-radius: 4px;

                        background-color: $black-faded;
                        color: $white;

                        &:hover {
                            cursor: pointer;
                            filter: brightness(90%);
                        }

                        .redirect-read-icon {
                            font-size: 24px;
                        }
                    }
                }

                .read-report-bttn {
                    padding: 8px 24px;
                    border: none;
                    background-color: $secondary;
                    color: $white;
                    border-radius: 4px;
                    font-size: 16px;

                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                        filter: brightness(90%);
                    }

                    .redirect-read-icon {
                        font-size: 24px;
                    }

                }
            }
        }
    }
}

// Handles Mobile
@media only screen and (max-width: 800px) {
    #research-container {
        #inner-research-container {
            height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;

            height: 100%;

            #report-list {
                width: 100%;
                padding: 0;
            }

            #viewing-report {
                width: 100%;
            }
        }
    }
}